import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Il sito del Maddo</h1>
      <img src="images/maddologopixel.png"></img>
      <p>Fatto in 15 minuti perché poco sbatti</p>
      <hr />
      <h2>Links</h2>
      <div className='linksList'>
        <a href="https://www.instagram.com/maddofoto/">Instagram</a>
        <a href="https://www.tiktok.com/@maddoscientisto">TikTok</a>
        <a href="images/Bingo_2024.png"><img src="images/bingo-thumbnail.png"/>Il Bingo di Rimini</a>
        <a href="images/Bingo_2024_smaller.png"><img src="images/bingo-thumbnail.png"/>Il Bingo di Rimini ma più piccolo</a>
        </div>
      <hr />
      <h2>Alcune foto game boy perché no</h2>
      <div class="imageGrid">
        <a href="#"><img src="images/image1.png" alt="Image 1" /></a>
        <a href="#"><img src="images/image2.png" alt="Image 2" /></a>
        <a href="#"><img src="images/image3.png" alt="Image 3" /></a>
        <a href="#"><img src="images/image4.png" alt="Image 4" /></a>
      </div>

    </div>
  );
}

export default App;
